body {
  margin: 0;
  font-family: Montserrat, Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  background-color: #001d3d;
  background: linear-gradient(0deg, #001d3d 0%, #003566 50%, #001d3d 100%);
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #003566;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
